import React from "react"
import Layout from "../components/layout"
import HeroUnit from "../components/index-page/hero-unit"
import Valorisations from "../components/index-page/valorisations"
import ModusOperandi from "../components/index-page/modusoperandi"
import Lithium from "../components/index-page/lithium"

const Home = () => {
    return (
        <Layout>
            <HeroUnit/>
            <Valorisations/>
            <ModusOperandi/>
            <Lithium/>
        </Layout>
    )
}
export default Home
