import React from "react"
import styled from "@emotion/styled"
import {StaticImage} from "gatsby-plugin-image"
import theme from "../../styles/theme"
import Button from "../button";

const Lithium = () => {
    return (
        <StyledLithium>
            <div className="inner">
                <h2>Programme batteries lithium</h2>

                <StyledDescription>
                    <div>
                        <p>Avec le soutien de la Région Sud, de l’ADEME et d’industriels privés, nous avons développé
                            une solution unique de revalorisation des batteries cylindriques (types lithium 18650, 26650
                            et 21700) largement utilisées par la mobilité électrique et la cigarette électronique.</p>
                        <p>La solution WeRECY dédiée à la seconde vie des batteries lithium, permet le démantèlement et
                            le diagnostic des cellules à l’échelle industrielle pour des applications de stockage
                            d’énergie (armoires industrielles, maisons autonomes, éclairage public isolé ...).</p>
                        <p>Le stade de maturité de ce programme prévoit la création d’une unité industrielle de réemploi
                            de batteries lithium à horizon 2024.</p>
                    </div>
                    <StaticImage
                        src='../../images/v2/batterie-lithium-schema-label.jpg'
                        layout="fullWidth"
                        alt=''
                    ></StaticImage>
                </StyledDescription>
                <StyledLogos>
                    <StyledBackgroundLines>
                        <Button className='button--blue' to={'/blog/re-lion-factory-la-seconde-vie-des-batteries-lithium'}>en savoir +</Button>
                    </StyledBackgroundLines>

                    <div className="logos">
                        <StaticImage
                            src='../../images/v2/logo-ademe.png'
                            alt=''
                            fit={'inside'}
                            height={80}
                        ></StaticImage>
                        <StaticImage
                            src='../../images/v2/logo-region-sud.jpeg'
                            alt=''
                            fit={'inside'}
                            height={80}
                        ></StaticImage>
                        <StaticImage
                            src='../../images/v2/logo-screlec.png'
                            alt=''
                            fit={'inside'}
                            height={80}
                        ></StaticImage>
                        <StaticImage
                            src='../../images/v2/logo-capenergies.jpeg'
                            alt=''
                            fit={'inside'}
                            height={80}
                        ></StaticImage>
                    </div>
                </StyledLogos>
            </div>
        </StyledLithium>
    )
}

const StyledLithium = styled.section`
    & > .inner {
        display: flex;
        flex-direction: column;
        gap: ${theme.layout.gridGap};
        align-items: center;
    }

    h2 {
        text-align: center;
    }

    p.description {
        text-align: center;
    }
`

const StyledDescription = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${theme.layout.gridGap};
    align-items: center;
    width: 100%;
    
    @media only screen and (min-width: ${theme.breakpoint.medium}px) {
        flex-direction: row;
    }
    
    & > *:first-child {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: ${theme.layout.gridGap};
    }
    
    & > *:last-child {
        flex: 2;
    }
`

const StyledLogos = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${theme.layout.sectionGridGap};
    align-items: center;
    justify-content: space-between;
    width: 100%;
    
    @media only screen and (min-width: ${theme.breakpoint.medium}px) {
        flex-direction: row;
        gap: ${theme.layout.gridGap};
    }
    
    & a {
        white-space: nowrap;
    }
    
    & > div.logos {
        display: flex;
        flex-direction: column;
        gap: ${theme.layout.gridGap};
        align-items: center;
    
        @media only screen and (min-width: ${theme.breakpoint.medium}px) {
            flex-direction: row;
        }
    }
`

const StyledBackgroundLines = styled.div`
    background: url('background-lines-pink.svg') no-repeat top right;
    background-size: 50px 50px;
    padding: 40px 40px 0px 0px;
`

export default Lithium
