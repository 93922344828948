import React from "react"
import styled from "@emotion/styled"
import {StaticImage} from "gatsby-plugin-image"
import theme from "../../styles/theme"

const Modusoperandi = () => {
    return (
        <StyledModusOperandi>
            <div className="inner">
                <h2>Modus Operandi</h2>

                <p className='description'>Avec nos experts juridiques, logistiques, laboratoires, écoles, bureaux
                    d’études, l’ADEME, TPE / PME grands groupes, nous appliquons pour chaque gisement :</p>

                <StyledProcessus>
                    <ul>
                        <li>Analyse qualitative et quantitative des compositions pour extraire le potentiel de
                            valorisation
                        </li>
                        <li>Étude d’impact environnemental</li>
                        <li>Evaluation du degré d’innovation</li>
                    </ul>
                    <ul>
                        <li>Benchmark des solutions de valorisations existantes et mobilisation des pure players
                            stratégiques
                        </li>
                        <li>Structuration financière du projet industriel</li>
                        <li>Analyse juridique</li>
                    </ul>
                </StyledProcessus>

                <StyledModus>

                    <div className="list">
                        <div>
                            <StaticImage
                                src='../../images/v2/modus-robotic-arm-icon.svg'
                                className='icon'
                                width={150}
                                aspectRatio={1}
                                alt=''></StaticImage>
                            <p>Dimensionnement de l’unité industrielle</p>
                        </div>
                        <div>
                            <StaticImage
                                src='../../images/v2/modus-project-icon.svg'
                                className='icon'
                                width={150}
                                aspectRatio={1}
                                alt=''></StaticImage>
                            <p>Suivi opérationnel de l'implémentation du projet</p>
                        </div>
                    </div>
                </StyledModus>
            </div>
        </StyledModusOperandi>
    )
}

const StyledModusOperandi = styled.section`
    background: ${theme.palette.primary};

    & > .inner {
        display: flex;
        flex-direction: column;
        gap: ${theme.layout.gridGap};
        align-items: center;
    }

    h2 {
        text-align: center;
        color: ${theme.palette.text};
    }
    
    p.description {
        text-align: center;
        color: ${theme.palette.text};
    }
`

const StyledProcessus = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: ${theme.palette.secondary};
    width: 100%;
    padding: 1rem;
    border-radius: ${theme.layout.borderRadius};
    
    @media only screen and (min-width: ${theme.breakpoint.medium}px) {
        flex-direction: row;
        padding: 2rem;
    }
    
    ul {
        li {
            color: ${theme.palette.text};
            font-size: 1.2rem;
        }
    }
`

const StyledModus = styled.div`
    display: flex;
    flex-direction: column-reverse;
    gap: ${theme.layout.gridGap};
    
    @media only screen and (min-width: ${theme.breakpoint.medium}px) {
        flex-direction: row;
    }
    
    .list {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: ${theme.layout.gridGap};
        align-items: center;
            
        @media only screen and (min-width: ${theme.breakpoint.medium}px) {
            margin-top: 50px;
            flex-direction: row;
            
            &::before {
                content: '';
                background: url('fleche.svg') no-repeat;
                width: calc(15px * 2);
                height: calc(43px * 2);
                position: absolute;
                top: -100px;
                left: 50%;
                transform: translate(-50%);
                z-index: 4;
            }
        }
        
        & > div {
            display: flex;
            flex-direction: column;
            gap: ${theme.layout.gridGap};
            align-items: center;
    
            @media only screen and (min-width: ${theme.breakpoint.medium}px) {
                flex-direction: row;
            }
            
            p {
                color: ${theme.palette.text};
                font-size: 2rem;
                text-align: center;
                
                @media only screen and (min-width: ${theme.breakpoint.medium}px) {
                    text-align: left;
                }
            }
        }
    }
    
`

export default Modusoperandi
