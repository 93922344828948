import React from "react"
import styled from "@emotion/styled"
import {StaticImage} from "gatsby-plugin-image"
import theme from "../../styles/theme";
import Button from "../button";
import {css} from "@emotion/react";

const Valorisations = () => {
    return (
        <StyledValorisations>
            <div className="inner">
                <h2>Programmes de valorisation WeRECY</h2>

                <div className="valuations__content">
                    <div className="valuations__description">
                        <p>Nos programmes visent à créer ou renforcer les technologies et les filières de valorisation
                            des familles
                            de gisements collectés.</p>

                        <div>
                            <StyledBackgroundLines>
                                <span>Un nouveau gisement&nbsp;?</span>
                            </StyledBackgroundLines>
                        </div>

                        <Button className="button--blue" href={"mailto:leo@werecy.com"}>Let's GO</Button>
                    </div>
                    <div className="valuations__pictures">
                        <div className="valuations__picture">
                            <div className="valuations__picture__blue">
                                <p>Batteries lithium</p>
                                <StaticImage
                                    src="../../images/v2/batterie-source.jpg"
                                    alt=""
                                    css={css({
                                        'width': '150px',
                                        'height': '150px',
                                        'borderRadius': theme.layout.borderRadius
                                    })}
                                ></StaticImage>
                            </div>
                            <div className="valuations__picture__red">
                                <StaticImage
                                    src="../../images/v2/batterie-resultat.jpg"
                                    alt=""
                                    css={css({
                                        'width': '150px',
                                        'height': '150px',
                                        'borderRadius': theme.layout.borderRadius
                                    })}></StaticImage>
                                <p>Stockage d'énergie</p>
                                <Button className="button--small button--black"
                                        to={'/blog/re-lion-factory-la-seconde-vie-des-batteries-lithium'}>en savoir
                                    +</Button>
                            </div>
                        </div>

                        <div className="valuations__picture">
                            <div className="valuations__picture__blue">
                                <p>Jeans</p>
                                <StaticImage
                                    src="../../images/v2/jean-source.JPG"
                                    alt=""
                                    css={css({
                                        'width': '150px',
                                        'height': '150px',
                                        'borderRadius': theme.layout.borderRadius
                                    })}
                                ></StaticImage>
                            </div>
                            <div className="valuations__picture__red">
                                <StaticImage
                                    src="../../images/v2/jean-resultat.jpg"
                                    alt=""
                                    css={css({
                                        'width': '150px',
                                        'height': '150px',
                                        'borderRadius': theme.layout.borderRadius
                                    })}></StaticImage>
                                <p>Isolation acoustique</p>
                                <Button className="button--small button--black"
                                        to={'/blog/jeans-une-re-valorisation-super-circulaire'}>en savoir +</Button>
                            </div>
                        </div>

                        <div className="valuations__picture">
                            <div className="valuations__picture__blue">
                                <p>Polymères</p>
                                <StaticImage
                                    src="../../images/v2/polymere-source.jpeg"
                                    alt=""
                                    css={css({
                                        'width': '150px',
                                        'height': '150px',
                                        'borderRadius': theme.layout.borderRadius
                                    })}></StaticImage>
                            </div>
                            <div className="valuations__picture__red">
                                <StaticImage
                                    src="../../images/v2/polymere-resultat.png"
                                    alt=""
                                    css={css({
                                        'width': '150px',
                                        'height': '150px',
                                        'borderRadius': theme.layout.borderRadius
                                    })}></StaticImage>
                                <p>Accessoires fitness</p>
                                <Button className="button--small button--black" to={'/blog/polymeres-une-transformation-revolutionnaire'}>en savoir
                                    +</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </StyledValorisations>
    )
}

const StyledValorisations = styled.section`
    & > .inner {
        display: flex;
        flex-direction: column;
        gap: ${theme.layout.gridGap};
    }

    h2 {
        text-align: center;
    }
    
    .valuations__content {
        display: flex;
        flex-direction: column;
        gap: ${theme.layout.gridGap};
        align-items: center;
    
        @media only screen and (min-width: ${theme.breakpoint.medium}px) {
            flex-direction: row;
        }
    
        .valuations__description {
            display: flex;
            flex-direction: column;
            gap: ${theme.layout.gridGap};
            align-items: flex-start;
        }
        
        .valuations__pictures {
            display: flex;
            flex-direction: column;
            gap: calc(${theme.layout.gridGap} / 2);
    
            @media only screen and (min-width: ${theme.breakpoint.medium}px) {
                flex-direction: row;
            }
            
            .valuations__picture {
                position: relative;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: stretch;
    
                @media only screen and (min-width: ${theme.breakpoint.medium}px) {
                    flex-direction: column;
                }
                
                p {
                    color: ${theme.palette.text};
                    text-align: center;
                    font-size: 1.2rem; 
                    line-height: 1.2em;
                }
            
                &__blue,
                &__red {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    gap: calc(${theme.layout.gridGap} / 2);
                    padding: 20px;
                    align-items: center;
                    justify-content: start;
                    
                    @media only screen and (min-width: ${theme.breakpoint.medium}px) {
                        padding: calc(${theme.layout.pagePadding} / 2);
                    }
                }
                
                &__blue {
                    flex-direction: column-reverse;
                    background: ${theme.palette.primary};
    
                    @media only screen and (min-width: ${theme.breakpoint.medium}px) {
                        flex-direction: column;
                    }
                }
                
                &__red {
                    background: ${theme.palette.common.pink};
                    
                    @media only screen and (min-width: ${theme.breakpoint.medium}px) {
                        &::before {
                            content: '';
                            background: url('fleche.svg') no-repeat;
                            width: calc(15px * 2);
                            height: calc(43px * 2);
                            position: absolute;
                            top: calc(43px * -1);
                            z-index: 4;
                        }
                    }
                }
            }
        }
    }
`

const StyledBackgroundLines = styled.div`
    background: url('background-lines-blue.svg') no-repeat top right;
    background-size: 50px 50px;
    padding: 40px 40px 0px 0px;
    
    span {
        font-size: 1.8rem;
        line-height: 1.2em;
        font-weight: ${theme.typography.fontWeight.bold};
        color: ${theme.palette.secondary};
    }
`

export default Valorisations
