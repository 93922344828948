import React from "react"
import styled from "@emotion/styled"

// import Downloads from "../downloads"
import Logo from "../../assets/logo-header.inline.svg"
import theme from "../../styles/theme"

const HeroUnit = () => {
    return (
        <StyledHeroUnit>
            <div className="inner">
                <StyledLogoWrapper>
                    <Logo alt="Logo WeRECY"/>
                </StyledLogoWrapper>

                <StyledTitle>
                    Industrial <span>ReUSE</span> solutions
                </StyledTitle>

                <StyledDescription>
                    Pionnière du super-circulaire, notre équipe apporte une réponse industrielle à
                    l’ensemble de la chaîne de valeur que constituent les boucles d’économie circulaire. Depuis la
                    collecte jusqu’à la réalisation de l’outil de revalorisation industrielle.
                </StyledDescription>
            </div>
        </StyledHeroUnit>
    )
}

const StyledHeroUnit = styled.section`
    padding-top: ${theme.layout.sectionGridGap};
    padding-bottom: calc(${theme.layout.sectionGridGap} * 2);
    background-color: ${theme.palette.background};
    color: ${theme.palette.text};
    
    & > .inner {
        display: flex;
        flex-direction: column;
        gap: ${theme.layout.gridGap};
        align-items: center;
    }
`

const StyledLogoWrapper = styled.div`
    width: 60%;
    max-width: ${theme.layout.pageWidth}px;
    
    @media only screen and (min-width: ${theme.breakpoint.medium}px) {
        width: 60%;
    }
`

const StyledTitle = styled.h1`
    font-family: ${theme.typography.fontFamily};
    font-weight: ${theme.typography.fontWeight.light};
    text-transform: uppercase;
    text-align: center;
    border-top: 2px solid ${theme.palette.text};
    border-bottom: 2px solid ${theme.palette.text};
    
    span {
        font-weight: ${theme.typography.fontWeight.bold};
        text-transform: none;
    }
`

const StyledDescription = styled.p`
    color: ${theme.palette.text};
    text-align: center;
`

export default HeroUnit
